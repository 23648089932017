import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "search",
      "style": {
        "position": "relative"
      }
    }}>{`Search`}</h1>
    <p>{`O campo de busca proporciona ao usuário encontrar uma palavra ou frase específica dentro da página sem a necessidade de usar a navegação.`}</p>
    <p><strong parentName="p">{`Filtro`}</strong>{`: O filtro pode ser usado como uma forma de limitar o conteúdo exibido na página. O filtro não substitui a ordenação.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Utilize labels para apresentar que resultados estão sendo exibidos. E apresente os resultados mais relevantes primeiro.`}</li>
      <li parentName="ul">{`Para buscas com muitos resultados utilize o componente de Paginação e indique quantos resultados foram encontrados.`}</li>
      <li parentName="ul">{`Exiba uma mensagem clara quando não forem encontrados resultados para busca e sempre que possível ofereça outras sugestões de busca.`}</li>
      <li parentName="ul">{`Mantenha o texto original no campo de input. Facilita a ação do usuário caso queira fazer uma nova busca com algumas modificações no input.`}</li>
      <li parentName="ul">{`Caso seja possível detectar um erro de digitação, mostre os resultados para a palavra "corrigida", isso evita a frustração de não obter resultados e não força o usuário a realizar uma nova busca.`}</li>
      <li parentName="ul">{`Quando apropriado, destaque os termos da busca nos resultados.`}</li>
    </ul>
    <h2 {...{
      "id": "exemplo",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplo`}</h2>
    <Demo src='pages/components/search/Search' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      